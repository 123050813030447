.tinymce_preview h2{
  color: #b90066;
  margin: 2em 0 1em 0;
}

.tinymce_preview p{
  color:black;
  line-height: 1.4;
}

.tinymce_preview li{
  line-height: 1.2;
}