body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.title {
  color: rgba(0, 0, 0, 0.87);
  height: 56px;
  line-height: 56px;
  overflow: hidden;
  opacity: 1;
  position: relative;
  padding-left: 0px;
  padding-right: 10px;
  text-overflow: ellipsis;
  top: 6px;
  white-space: nowrap;
  display: inline-block;
}

.filterField {
  vertical-align: bottom;
}

.filterTrash {
  vertical-align: middle;
}

/*# sourceMappingURL=DynamicFilter.cssmodule.css.map */

.header {
  cursor: pointer;
  vertical-align: middle;
  color: #fff;
}
.header:hover .icon {
  opacity: 0.3;
}
.header.active .icon {
  opacity: 1;
}
.header .text {
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}
.header .icon {
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  color: #fff;
  transition: opacity 0.3s;
}

.activeRowColumn path {
  color: #fff;
  stroke: #fff;
  fill: #fff;
}

/*# sourceMappingURL=DynamicList.cssmodule.css.map */

@charset "UTF-8";
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/montserrat-v15-latin-regular.5cc74ef8.eot); /* IE9 Compat Modes */
  src: local(""), url(/static/media/montserrat-v15-latin-regular.5cc74ef8.eot?#iefix) format("embedded-opentype"), url(/static/media/montserrat-v15-latin-regular.bc3aa95d.woff2) format("woff2"), url(/static/media/montserrat-v15-latin-regular.8102c483.woff) format("woff"), url(/static/media/montserrat-v15-latin-regular.6a9e85ac.ttf) format("truetype"), url(/static/media/montserrat-v15-latin-regular.f3fef7e5.svg#Montserrat) format("svg"); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/montserrat-v15-latin-500.de95b8bf.eot); /* IE9 Compat Modes */
  src: local(""), url(/static/media/montserrat-v15-latin-500.de95b8bf.eot?#iefix) format("embedded-opentype"), url(/static/media/montserrat-v15-latin-500.92d16e45.woff2) format("woff2"), url(/static/media/montserrat-v15-latin-500.8b763220.woff) format("woff"), url(/static/media/montserrat-v15-latin-500.8b7179f8.ttf) format("truetype"), url(/static/media/montserrat-v15-latin-500.10c07b65.svg#Montserrat) format("svg"); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/montserrat-v15-latin-700.affb7169.eot); /* IE9 Compat Modes */
  src: local(""), url(/static/media/montserrat-v15-latin-700.affb7169.eot?#iefix) format("embedded-opentype"), url(/static/media/montserrat-v15-latin-700.39d93cf6.woff2) format("woff2"), url(/static/media/montserrat-v15-latin-700.80f10bd3.woff) format("woff"), url(/static/media/montserrat-v15-latin-700.7432889d.ttf) format("truetype"), url(/static/media/montserrat-v15-latin-700.f9c15375.svg#Montserrat) format("svg"); /* Legacy iOS */
}
body {
  font-family: "Montserrat", Arial;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.loadingCircle {
  margin-right: 1em;
  vertical-align: middle;
}

.Form #message-helper-text {
  color: #b90066;
  font-size: 1rem;
}

/*
Message personnalisé et affichage image carte CPS pour inscription en tant que médecin (md), infimier (in) et pharmacien (ph)
*/
:is(.job_es10, .job_es60, .job_es21) #accountFirstname-helper-text::after, :is(.job_es10, .job_es60, .job_es21) #accountLastname-helper-text::after {
  color: #b90066;
  font-weight: bold;
  cursor: help;
  background-image: url("/question.png");
  background-size: 16px 16px;
  display: inline-block;
  content: "Doit correspondre EXACTEMENT tel qu'indiqu\E9   sur votre carte CPS (tirets, accents\2026)";
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: left center;
  font-size: 90%;
}

:is(.job_es10, .job_es60, .job_es21) #accountFirstname-helper-text:hover::before, :is(.job_es10, .job_es60, .job_es21) #accountLastname-helper-text:hover::before {
  position: absolute;
  left: -10px;
  z-index: 10;
  display: inline-block;
  border: #848484 2px solid;
  border-radius: 15px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5647058824));
  content: "";
}

.job_es10 #accountFirstname-helper-text:hover::before, .job_es10 #accountLastname-helper-text:hover::before {
  background-image: url("/carte_cps_md.png");
  background-size: 402px 253px;
  width: 402px;
  height: 253px;
  top: -190px;
}

.job_es60 #accountFirstname-helper-text:hover::before, .job_es60 #accountLastname-helper-text:hover::before {
  background-image: url("/carte_cps_in.png");
  background-size: 357px 231px;
  width: 357px;
  height: 231px;
  top: -170px;
}

.job_es21 #accountFirstname-helper-text:hover::before, .job_es21 #accountLastname-helper-text:hover::before {
  background-image: url("/carte_cps_ph.png");
  background-size: 336px 212px;
  width: 336px;
  height: 212px;
  top: -150px;
}

/*
 Couleurs spécifiques Séries
*/
:root {
  --bg-tout: #660038;
  --bg-asthme: #B90066;
  --bg-bpco: #53585F;
  --bg-parcours: #2B3364;
  --bg-tech: #2A566B;
}

.categ_tout .SerieAction, .categ_tout .selected {
  background-color: #660038 !important;
  background-color: var(--bg-tout) !important;
}

.SeriesHeader.categ_tout {
  background-image: url("/bg_purple.jpeg");
  background-size: cover;
}

.categ_bpco .SerieAction, .categ_bpco .selected {
  background-color: #53585F !important;
  background-color: var(--bg-bpco) !important;
}

.SeriesHeader.categ_bpco {
  background-image: url("/bg_grey.svg");
  background-size: cover;
}

.categ_asthme .SerieAction, .categ_asthme .selected {
  background-color: #B90066 !important;
  background-color: var(--bg-asthme) !important;
}

.SeriesHeader.categ_asthme {
  background-image: url("/bg_pink.svg");
  background-size: cover;
}

.categ_parcours .SerieAction, .categ_parcours .selected {
  background-color: #2B3364 !important;
  background-color: var(--bg-parcours) !important;
}

.SeriesHeader.categ_parcours {
  background-image: url("/bg_violet.svg");
  background-size: cover;
}

.categ_tech .SerieAction, .categ_tech .selected {
  background-color: #2A566B !important;
  background-color: var(--bg-tech) !important;
}

.SeriesHeader.categ_tech {
  background-image: url("/bg_blue.svg");
  background-size: cover;
}

/*# sourceMappingURL=App.css.map */


.tinymce_preview h2{
  color: #b90066;
  margin: 2em 0 1em 0;
}

.tinymce_preview p{
  color:black;
  line-height: 1.4;
}

.tinymce_preview li{
  line-height: 1.2;
}
